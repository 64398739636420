@import url('https://cdn.ridelinks.com/css/ridelinks-core.css?v=1');

body {
    background-color: var(--offwhite);
    background-image: url("https://cdn.ridelinks.com/error-pages/ridelinks-map-white-bg75.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
h1 {
    color: var(--grayblack);
}
#wrap {
    max-width: 600px;
    margin: 10% auto 0;
}
.ridelinks-logo {
    display: block;
    width: 100px;
    margin-bottom: 10px;
}
.not-found-icon {
    opacity: 0.5;
    width: 220px;
    margin: 0 auto 20px 25%;
    display: block;
}

#ref-string {
    white-space: nowrap;
}